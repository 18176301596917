import React, { Suspense } from 'react';
import { useParams, ScrollRestoration } from 'react-router-dom';
import Categories from 'components/Categories';
import CategoryComponent from 'components/Category';
import H4 from 'components/Typo/H4';
import H5 from 'components/Typo/H5';
import BlankBanner from 'components/Banners/BlankBanner';
import { CategoryName, setUppercaseFirstLetter } from 'utils/consts';
import { Skeleton } from '@mui/material';
import Ad from 'components/Advertisement';
import componentLoader from 'utils/lazyLoader';

const SponsoredBanner = React.lazy(() =>
  componentLoader(() => import('components/Banners/SponsoredBanner'), 3)
);
const PetsBanner = React.lazy(() =>
  componentLoader(() => import('components/Banners/PetsBanner'), 3)
);
const HomeBanner = React.lazy(() =>
  componentLoader(() => import('components/Banners/HomeBanner'), 3)
);
const TravelBanner = React.lazy(() =>
  componentLoader(() => import('components/Banners/TravelBanner'), 3)
);
const EnterBanner = React.lazy(() =>
  componentLoader(() => import('components/Banners/EntartainmentBanner'), 3)
);
const FoodBanner = React.lazy(() =>
  componentLoader(() => import('components/Banners/FoodBanner'), 3)
);

function Banners({ name }: { name: string }): JSX.Element {
  const n = name.toLowerCase();

  if (n === CategoryName.Enter.toLowerCase()) {
    return <EnterBanner />;
  }

  if (n === CategoryName.Pets.toLowerCase()) {
    return <PetsBanner />;
  }

  if (n === CategoryName.Food.toLowerCase()) {
    return <FoodBanner />;
  }

  if (n === CategoryName.Home.toLowerCase()) {
    return <HomeBanner />;
  }

  if (n === CategoryName.Travel.toLowerCase()) {
    return <TravelBanner />;
  }

  return <SponsoredBanner />;
}

function Category() {
  const { categoryId } = useParams();

  const title = setUppercaseFirstLetter(categoryId || 'unknown');
  return (
    <>
      <Suspense fallback={<BlankBanner />}>
        {categoryId && <Banners name={categoryId} />}
      </Suspense>

      {title ? (
        <H4 sx={{ marginBottom: 2 }}>{title}</H4>
      ) : (
        <Skeleton variant="rounded" width={50} />
      )}

      <Ad />

      <CategoryComponent />
      <H5>Other categories</H5>
      <Categories hideId={categoryId} />
      <ScrollRestoration />

      <Ad />
    </>
  );
}

export default Category;
