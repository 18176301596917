import React from 'react';
import wrapIframe from './utils';

const size = {
  width: 320,
  height: 50,
};

const iframe = wrapIframe(size);

const Ad = (props: any) => {
  return (
    <div
      {...props}
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      dangerouslySetInnerHTML={{__html: iframe}}
    >
    </div>
  )
};

export default Ad;
