export default function wrapIframe(size: { width: number, height: number }): string {
  const { width, height } = size;

  const adtag = `<script type='text/adtag'>
  <ins class='dcmads' style='display:inline-block;width:${width}px;height:${height}px'
      data-dcm-placement='N6246.1912641HEALTHMEDIANETWORK/B29099353.357939586'
      data-dcm-rendering-mode='script'
      data-dcm-https-only
      data-dcm-ltd='false'
      data-dcm-resettable-device-id=''
      data-dcm-app-id=''>
    <script src='https://www.googletagservices.com/dcm/dcmads.js'></scr+ipt>
  </ins>
  </script>
  <script
    language='javascript'
    type='text/javascript'
    src='https://cdn.doubleverify.com/dvbs_src.js?ctx=17256681&cmp=29099353&plc=357939586&sid=5099770&dvregion=0&unit=${width}x${height}'
  ></script>`;

  const styles = `display:inline-block;width:${width}px;height:${height}px;border:none`;

  const iframe = `
  <iframe
    style="${styles}"
    src="data:text/html,${encodeURIComponent(`<style>body{margin:0}</style>${adtag}`)}"
  ></iframe>`;

  return iframe;
}
