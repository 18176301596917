import { styled } from '@mui/material/styles';
import { Theme } from 'theme';

export const AdBox = styled('div')(({ theme }: { theme: Theme }) => ({
  background: theme.palette.neutral.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  overflow: 'hidden',
  margin: `0 -${theme.spacing(2)}`,
}));

export const AdBoxSmall = styled(AdBox)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const AdBoxBig = styled(AdBox)(({ theme }: { theme: Theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
