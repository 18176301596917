import React from 'react';
import wrapIframe from './utils';

const size = {
  width: 728,
  height: 90,
};

const iframe = wrapIframe(size);

const Ad = (props: any) => (
  <div
    {...props}
    style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}

    dangerouslySetInnerHTML={{__html: iframe}}
  >
  </div>
);

export default Ad;
