import React, { useEffect, useState } from 'react';

import Ad320x50 from 'components/Advertisement/320x50';
import Ad728x90 from 'components/Advertisement/728x90';

import { AdBox } from './styles';

function Ad() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 800);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [isDesktop]);

  return (
    <AdBox>
      {isDesktop ? <Ad728x90 /> : <Ad320x50 />}
    </AdBox>
  );
}

export default Ad;
